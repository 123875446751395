import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { StringParam, useQueryParams } from 'use-query-params';
import fetch from 'utils/fetch';
import { setUser } from 'utils/persistentStore';
import { getClientName } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: 0,
  },
  loading: {
    marginTop: theme.spacing(3),
  },
}));

export default function SSORedirect() {
  const [query] = useQueryParams({
    code: StringParam,
  });
  const classes = useStyles();
  const [error, setError] = useState('');

  useQuery(
    ['sso_login', query?.code],
    () =>
      fetch({
        method: 'POST',
        url: `/core/sso_login/cookie`,
        data: { subdomain: getClientName(), authorizationCode: query.code, nonce: 'qvzs6prcic' },
      }),
    {
      onSuccess: () => {
        setUser({}); //to allow login
        window.location.replace('/ct/dashboard/sales/');
      },
      onError: (err) => {
        setError(`${err.data.error}: ${err.data.message}`);
      },
      suspense: false,
      throwOnError: false,
      useErrorBoundary: false,
    }
  );

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <div className={classes.paperContainer}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Logging in...
          </Typography>
          {error ? (
            <Typography className={classes.loading} color="secondary">
              {error}
            </Typography>
          ) : (
            <CircularProgress className={classes.loading} />
          )}
        </div>
      </Paper>
    </main>
  );
}
